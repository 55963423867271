var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap portTerminalCy",
      staticStyle: { width: "850px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          staticStyle: { "z-index": "auto" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c(
          "div",
          { staticClass: "popup_dim", attrs: { id: "port_popup" } },
          [
            _vm.customComponent
              ? _c(_vm.customComponent, {
                  tag: "component",
                  attrs: { "parent-info": _vm.parentInfoPort },
                  on: { close: _vm.closePopup },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.105"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("p", { staticClass: "txt_desc none" }, [
            _vm._v(" " + _vm._s(_vm.currentTimeVO.plcNm) + " "),
            _vm.currentTimeVO.plcNm ? _c("br") : _vm._e(),
            _vm._v(
              " " +
                _vm._s(_vm.$t("msg.VOSD100_M5.064")) +
                " : " +
                _vm._s(
                  _vm.changeDateTime2Display(
                    _vm.currentTimeVO.localDtm,
                    _vm.lang
                  )
                ) +
                " (" +
                _vm._s(_vm.currentTimeVO.tmf) +
                _vm._s(_vm.$t("msg.VOSD100_M5.065")) +
                ") "
            ),
          ]),
          _vm.terminalInfo1VO && _vm.terminalInfo1VO.length > 0
            ? _c("table", { staticClass: "tbl_col mt10" }, [
                _vm._m(0),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.102")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.066")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.067")))]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.terminalInfo1VO, function (terminal, index) {
                    return _c("tr", { key: index }, [
                      _c("td", { staticClass: "text_left" }, [
                        _vm._v(
                          _vm._s(terminal.cyEnm) +
                            "(" +
                            _vm._s(terminal.cyCd) +
                            ")"
                        ),
                      ]),
                      _c("td", { staticClass: "text_left" }, [
                        _vm._v(" " + _vm._s(terminal.cyAddr) + " "),
                        terminal.cyAddr
                          ? _c("span", { staticClass: "ml5" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "tbl_icon place",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openMapPopup(terminal)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("msg.VOSD100_M5.066")) +
                                      " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("td", { staticClass: "text_left" }, [
                        _c(
                          "a",
                          {
                            staticClass: "td_underline",
                            attrs: {
                              href: terminal.hmpgUrlAddr,
                              target: "_black",
                            },
                          },
                          [_vm._v(_vm._s(terminal.hmpgUrlAddr))]
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm.icdNm
            ? _c("table", { staticClass: "tbl_col mt10" }, [
                _vm._m(1),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.103")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.066")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.067")))]),
                  ]),
                ]),
                _c("tbody", [
                  _c(
                    "tr",
                    [
                      _c("td", { staticClass: "text_left" }, [
                        _vm._v(_vm._s(_vm.icdNm)),
                      ]),
                      _vm.icdInfoVO && _vm.icdInfoVO.length > 0
                        ? [
                            _vm._l(_vm.icdInfoVO, function (icd, index) {
                              return [
                                _c(
                                  "td",
                                  {
                                    key: index + "_addr",
                                    staticClass: "text_left",
                                  },
                                  [
                                    _vm._v(" " + _vm._s(icd.cyAddr) + " "),
                                    icd.cyAddr
                                      ? _c("span", { staticClass: "ml5" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "tbl_icon place",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openMapPopup(icd)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("msg.VOSD100_M5.066")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    key: index + "_hmpgAddr",
                                    staticClass: "text_left",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "td_underline",
                                        attrs: {
                                          href: icd.hmpgUrlAddr,
                                          target: "_black",
                                        },
                                      },
                                      [_vm._v(_vm._s(icd.hmpgUrlAddr))]
                                    ),
                                  ]
                                ),
                              ]
                            }),
                          ]
                        : [_c("td"), _c("td")],
                    ],
                    2
                  ),
                ]),
              ])
            : _vm._e(),
          _c("table", { staticClass: "tbl_col mt10" }, [
            _vm._m(2),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.104")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.066")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.068")))]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.agentList, function (agent, index) {
                return _c("tr", { key: index }, [
                  _c("td", { staticClass: "text_left" }, [
                    _vm._v(
                      _vm._s(
                        _vm.lang === "KOR" || agent.orgEnm === undefined
                          ? agent.orgNm
                          : agent.orgEnm
                      )
                    ),
                  ]),
                  _c("td", { staticClass: "text_left" }, [
                    _vm._v(" " + _vm._s(agent.subAddr) + " "),
                  ]),
                  _c("td", { staticClass: "text_left" }, [
                    _vm._v(" " + _vm._s(agent.telNo) + " "),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.VOSD100.042")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "24%" } }),
      _c("col", { attrs: { width: "46%" } }),
      _c("col", { attrs: { width: "30%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "24%" } }),
      _c("col", { attrs: { width: "46%" } }),
      _c("col", { attrs: { width: "30%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "24%" } }),
      _c("col", { attrs: { width: "46%" } }),
      _c("col", { attrs: { width: "30%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }