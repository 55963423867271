<template>
  <div
    class="popup_wrap"
    style="width:1300px; height:600px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont" ><!-- popup_cont -->
    <h1 class="page_title">{{ $t('msg.VOSD100_M5.011') }}<!-- 스케줄조회 --></h1>
      <ScheduleLeg @close="closePopup" @ScheduleLegPop="throwBkgParams" :parentInfo="parentInfo"></ScheduleLeg>
    </div><!-- popup_cont -->
  </div>
</template>
<script>
import ScheduleLeg from '@/pages/schedule/ScheduleLeg'

export default {
  name: 'scheduleLegPop',
  computed: {
  },
  components: {
    ScheduleLeg
  },
  created () {
    console.log('<<<<<< scheduleLegPop / parentInfo >>>>>>')
    console.log(this.parentInfo)
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          ScheduleLegPop: 'N',
          cancelRequestPop: 'N',
          rfYn: 'Y'
        }
      }
    }
  },
  data: function () {
    return {}
  },
  watch: {
  },
  methods: {
    closePopup () {
      this.$emit('close')
    },
    throwBkgParams (obj) {
      console.log('@@@@@ Pop-up >>> throwBkgParams obj Data @@@@@')
      console.log(obj)
      this.$emit('selectFunc', obj)
    }
  }
}
</script>
