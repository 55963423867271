<template>
  <l-map
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
  >
    <l-tile-layer
      :url="url"
      :attribution="attribution"
    />
    <l-control>
      <img src="../../assets/images/schedule/kmtc_logo.gif" style="width: 150px;">
    </l-control>
    <l-control
      v-if="parentInfo.catCd === 'V'"
      :position="'bottomleft'"
    >
      <div>{{ $t('msg.SCHD010P040.047') }}:{{ vslUpdateTime }}</div><!-- 최근 업데이트 시간 -->
    </l-control>
    <l-marker
      v-for="(m,index) in makerLatLngList" :key="index"
      :lat-lng="m"
      :icon="icon"
    >
      <l-tooltip>
        {{ courseInfoList[index].ctrNm }} / {{ courseInfoList[index].portNm }}
      </l-tooltip>
    </l-marker>
    <l-polyline
        v-for="item in polylines"
        :key="item.id"
        :lat-lngs="item.points"
        :visible="item.visible"
        @click="alert(item)"
      />
  </l-map>
</template>

<script>

import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import { latLng, icon } from 'leaflet'
import { LMap, LTileLayer, LMarker, LControl, LTooltip, LPolyline } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'

export default {
  name: 'RouteInfo',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    LPolyline,
    LTooltip
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          // 업무 구분 코드(V=선박위치, P=항구위치)
          catCd: 'V',
          // 선박위치 관련 데이터
          vslCd: '',
          voyNo: '',
          vslNm: '',
          // 항구위치 관련 데이터
          portCd: ''
        }
      }
     }
  },
  data: function () {
    return {
      // 지도 확대정도
      zoom: 5,
      // 지도 중앙 위/경도(default - 서울)
      center: latLng(37.564214, 127.001699),
      // 지도 호출 url
      // url: 'https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=cKpaWGCFTEuLbSeKgbTi',
      url: 'https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png',
      // 우측 하단 표시
      // attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
      attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
      // 아이콘 표시 위/경도(default - 서울)
      makerLatLng: latLng(37.564214, 127.001699),
      // 지도 옵션
      mapOptions: {
        zoomSnap: 0.5,
        minZoom: 3,
        maxZoom: 7
      },
      // 아이콘 옵션
      icon: icon({
        iconUrl: '/img/icon_harbor.png',
        iconSize: [24, 24],
        iconAnchor: [20, 20]
      }),
      // 선박 위치 수신일자
      vslUpdateTime: '',
      // 포트 정보
      makerLatLngList: [],
      polylines: [],
      points4Lines: {},
      points: [],
      eachPts: {},
      //
      courseInfoList: [],
      courseInfo: {},
      rteCd: '',
      vesselNm: '',
      vesselCd: '',
      vslVoy: '',
      yyyymmdd: '20211013',
      // center: {
      //   lat: parseFloat(37.564214),
      //   lng: parseFloat(127.001699)
      // },
      // zoom: 5,
      markersList: [],
      positionCover: {},
      position: {}
    }
  },
  created () {
    // 선박 실시간 위치 지도에 표시
    this.getLocationToMap(this.$props.parentInfo)
    console.log('>>>>>> [Route] this.parentInfo <<<<<<')
    console.log(this.parentInfo)
    this.getServiceCourseInfo()
  },
  methods: {
    getServiceCourseInfo () {
      const params = {
        vslNm: this.parentInfo.vesselNm,
        vslCd: this.vesselCd,
        rteCd: this.parentInfo.rteCd,
        searchDate: this.yyyymmdd,
        vslVoy: this.vslVoy
      }
      vslScheduleInfo.getServiceCourseInfo(params).then((rtnData) => {
        console.log('######### [Route] getServiceCourseInfo #########')
        console.log(rtnData)
        this.courseInfoList = rtnData.data.vesselScheduleList
        this.courseInfo = rtnData.data.serviceCourseInfo
        console.log('>>>>>> [Route] this.courseInfoList <<<<<<')
        console.log(this.courseInfoList)
        this.center.lat = parseFloat(this.courseInfo.courseLat)
        this.center.lng = parseFloat(this.courseInfo.courseLng)
        this.center = latLng(this.courseInfo.courseLat, this.courseInfo.courseLng)
        // this.makerLatLng = latLng(this.courseInfo.courseLat, this.courseInfo.courseLng)
        this.zoom = parseInt(this.courseInfo.zoom)
        this.markersList = []
        for (const item of this.courseInfoList) {
          this.position = {}
          this.position.lat = parseFloat(item.lttdNm)
          this.position.lng = parseFloat(item.lngtdNm)
          this.positionCover = {}
          this.positionCover.position = this.position
          this.markersList.push(this.positionCover)
          this.makerLatLng = latLng(item.lttdNm, item.lngtdNm)
          this.makerLatLngList.push(this.makerLatLng)
          // PolyLines
          this.eachPts = {}
          this.eachPts.lat = parseFloat(item.lttdNm)
          this.eachPts.lng = parseFloat(item.lngtdNm)
          this.points.push(this.eachPts)
          this.points4Lines = {}
          this.points4Lines.id = 'p1'
          this.points4Lines.points = this.points
          this.points4Lines.visible = true
          this.polylines.push(this.points4Lines)
        }
        console.log(this.markersList)
      })
    },
    getLocationToMap (data) {
      console.log(data)
      const catCd = data.catCd

      if (catCd === 'V') {
        // 선박 위치 API 호출
        vslScheduleInfo.getVslLocationInfo(data)
          .then(response => {
            console.log(response)
            if (response.status === 200) {
              const resData = response.data

              this.center = latLng(resData.lttd, resData.lngtd)
              this.makerLatLng = latLng(resData.lttd, resData.lngtd)
              this.vslUpdateTime = this.$ekmtcCommon.changeDatePattern(resData.aisRecvDtm, '-')
            }
          })
          .catch(error => {
            console.log(error)
          })
      } else if (catCd === 'P') {
        // 항구 위치 API 호출
        vslScheduleInfo.getPortLocationInfo(data)
          .then(response => {
            console.log(response)
            if (response.status === 200) {
              const resData = response.data

              this.center = latLng(resData.lttd, resData.lngtd)
              this.makerLatLng = latLng(resData.lttd, resData.lngtd)
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }

}
</script>
