import Send from '../../common.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  //searchCFSInter - 고객센터 > 정보조회 > 인터지스CFS 입고조회 - GET /common/info/intergis-cfs
  searchCFSInter (data) {
    return Send({
      url: '/common/info/intergis-cfs',
      method: 'get',
      params: data
    })
  },
  //popCfsReq - 고객센터 > 정보조회 > 인터지스CFS 입고조회 > 상세 - GET /common/info/intergis-cfs/{reqRno}
  popCfsReq (data) {
    const reqRno = data.reqRno
    return Send({
      url: '/common/info/intergis-cfs/' + reqRno,
      method: 'get'
    })
  },
  //insertCfsReq - 고객센터 > 정보조회 > 인터지스CFS 입고조회 > 등록/수정 - POST /common/info/intergis-cfs
  insertCfsReq (data) {
    return Send({
      url: '/common/info/intergis-cfs',
      method: 'post',
      data: data
    })
  },
  //updateCfsReq - 고객센터 > 정보조회 > 인터지스CFS 입고조회 > 수정(Booking No/Container No & Seal No 일괄 수정) - PUT /common/info/intergis-cfs
  updateCfsReq (data) {
    return Send({
      url: '/common/info/intergis-cfs',
      method: 'put',
      data: data
    })
  },
  //searchCFSInterImg - 고객센터 > 정보조회 > 인터지스CFS 사진조회 - GET /common/info/intergis-cfs-imgs
  searchCFSInterImg (data) {
    return Send({
      url: '/common/info/intergis-cfs-imgs',
      method: 'get',
      params: data
    })
  },
  //popCfsImg - 고객센터 > 정보조회 > 인터지스CFS 사진조회 > 상세 - GET /common/info/intergis-cfs-imgs/img
  popCfsImg (data) {
    return Send({
      url: '/common/info/intergis-cfs-imgs/img',
      method: 'get',
      params: data
    })
  },
  //엑셀 템플릿 다운로드
  excelDownload (fileName) {
    return Send({
      method: 'get',
      url: '/common/info/intergis-cfs/static-download/' + fileName,
      responseType: 'blob'
    }).catch((err) => {
      console.log(err)
    })
  }
}
