import Send from '../../schedule.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  // insertEmailSchedule - 스케줄 > 스케쥴 구독 저장 - POST /schedule/leg/email-schedule
  insertEmailSchedule (data) {
    return Send({
      url: '/schedule/leg/email-schedule',
      method: 'post',
      data: data
    })
  },
  // sendMail - 스케줄 > Long Term 메일 발송 - POST /schedule/term/send-mail
  sendMail (data) {
    return Send({
      url: '/schedule/term/send-mail',
      method: 'post',
      data: data
    })
  }
}
