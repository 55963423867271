<template>
  <div
    class="popup_wrap portTerminalCy"
    style="width:850px; height:600px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
      style="z-index: auto;"
    >
      close
    </button>
    <div class="popup_cont">
      <div class="popup_dim" id="port_popup">
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :parent-info="parentInfoPort"
        />
      </div>
      <h1 class="page_title">{{ $t('msg.VOSD100_M5.105') }}</h1> <!-- Port/Terminal/CY -->
      <div class="content_box">
        <!-- content_box -->
        <p class="txt_desc none">
          {{ currentTimeVO.plcNm }}
          <br v-if="currentTimeVO.plcNm" />
          {{ $t('msg.VOSD100_M5.064') }} : {{ changeDateTime2Display(currentTimeVO.localDtm, lang) }} ({{ currentTimeVO.tmf }}{{ $t('msg.VOSD100_M5.065') }})
        </p>
        <table class="tbl_col mt10" v-if="terminalInfo1VO && terminalInfo1VO.length > 0">
          <colgroup>
            <col width="24%">
            <col width="46%">
            <col width="30%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.VOSD100_M5.102') }}</th> <!-- Terminal Info -->
              <th>{{ $t('msg.VOSD100_M5.066') }}</th> <!-- 주소 -->
              <th>{{ $t('msg.VOSD100_M5.067') }}</th> <!-- 홈페이지 -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(terminal, index) in terminalInfo1VO" :key="index">
              <td class="text_left">{{ terminal.cyEnm }}({{ terminal.cyCd }})</td>
              <td class="text_left">
                {{ terminal.cyAddr }}
                <span class="ml5" v-if="terminal.cyAddr">
                  <button class="tbl_icon place" @click="openMapPopup(terminal)">
                    {{ $t('msg.VOSD100_M5.066') }}
                  </button>
                </span>
              </td>
              <td class="text_left"><a class="td_underline" :href="terminal.hmpgUrlAddr" target="_black">{{ terminal.hmpgUrlAddr }}</a></td>
            </tr>
          </tbody>
        </table>
        <table class="tbl_col mt10" v-if="icdNm">
          <colgroup>
            <col width="24%">
            <col width="46%">
            <col width="30%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.VOSD100_M5.103') }}</th> <!-- ICD Info -->
              <th>{{ $t('msg.VOSD100_M5.066') }}</th> <!-- 주소 -->
              <th>{{ $t('msg.VOSD100_M5.067') }}</th> <!-- 홈페이지 -->
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text_left">{{ icdNm }}</td>
              <template v-if="icdInfoVO && icdInfoVO.length > 0">
                <template v-for="(icd, index) in icdInfoVO">
                  <td class="text_left" :key="index + '_addr'">
                    <!-- 주소 -->
                    {{ icd.cyAddr }}
                    <span class="ml5" v-if="icd.cyAddr">
                      <button class="tbl_icon place" @click="openMapPopup(icd)">
                        {{ $t('msg.VOSD100_M5.066') }}
                      </button>
                    </span>
                  </td>
                  <td class="text_left" :key="index + '_hmpgAddr'">
                    <a class="td_underline" :href="icd.hmpgUrlAddr" target="_black">{{ icd.hmpgUrlAddr }}</a>
                  </td>
                </template>
              </template>
              <template v-else>
                <td></td>
                <td></td>
              </template>
            </tr>
          </tbody>
        </table>
        <table class="tbl_col mt10">
          <colgroup>
            <col width="24%">
            <col width="46%">
            <col width="30%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.VOSD100_M5.104') }}</th> <!-- KMTC Agent -->
              <th>{{ $t('msg.VOSD100_M5.066') }}</th>
              <th>{{ $t('msg.VOSD100_M5.068') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(agent, index) in agentList" :key="index">
              <td class="text_left">{{ lang === 'KOR' || agent.orgEnm === undefined ? agent.orgNm : agent.orgEnm }}</td>
              <td class="text_left">
                {{ agent.subAddr }}
              </td>
              <td class="text_left">
                {{ agent.telNo }}
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <a class="button gray lg" href="#" @click="$emit('close')">{{ $t('msg.VOSD100.042') }}<!-- 닫기 --></a>
      </div>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'

export default {
  name: 'PortTerminalCY',
  components: {
    'port-location-pop': () => import('@/pages/schedule/popup/PortLocationPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          asPortCd: '',
          asCyCd: '',
          asSvcLang: '01',
          plcCatCd: '',
          flag: ''
        }
      }
    }
  },
  data: function () {
    return {
      icdNm: '',
      terminalInfo1VO: {}, //TERMINAL 정보
      icdInfoVO: {}, //ICD 정보
      icdList: [], //ICD SELECTBOX
      agentList: [], //AGENT 정보
      currentTimeVO: {},
      regionInfoVO: {},
      parentInfoPort: {},
      lang: 'KOR',
      customComponent: null,
      icdPlcCd: this.parentInfo.icdPlcCd
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    if (this.auth !== undefined) {
      this.lang = this.auth.serviceLang
    }
    // console.log('>>>>>> [PortTerminalCY] this.parentInfo <<<<<<')
    // console.log(this.parentInfo)
    this.getPortTerminalCY()
  },
  methods: {
    //Port/Terminal/Cy 데이터 find & setting
    async getPortTerminalCY () {
      //INLAND여부 체크
      const icdResult = await vslScheduleInfo.getIcdList(this.parentInfo)
      if (icdResult.data && icdResult.data.length > 0) {
        this.icdNm = icdResult.data[0].plcNm
      }
      if (icdResult.data && icdResult.data.length > 0) {
        this.parentInfo.plcCatCd = '02'
      } else {
        this.parentInfo.plcCatCd = '01'
      }

      //해당 PORT에 대한 터미널 또는 ICD 데이터, Local Agent 데이터 가져오기
      vslScheduleInfo.getPortTerminalCY(this.parentInfo).then((rtnData) => {
        this.currentTimeVO = rtnData.data.currentTimeVO
        this.regionInfoVO = rtnData.data.regionInfoVO
        if (rtnData.data.terminalInfo1VO) {
          this.terminalInfo1VO = rtnData.data.terminalInfo1VO.map(obj => {
            if (obj.hmpgUrlAddr && obj.hmpgUrlAddr.indexOf('http') === -1) {
              obj.hmpgUrlAddr = 'http://' + obj.hmpgUrlAddr
            }
            return obj
          })
        }
        if (rtnData.data.icdInfoVO) {
          this.icdInfoVO = rtnData.data.icdInfoVO.map(obj => {
            if (obj.hmpgUrlAddr && obj.hmpgUrlAddr.indexOf('http') === -1) {
              obj.hmpgUrlAddr = 'http://' + obj.hmpgUrlAddr
            }
            return obj
          })
        }
        this.agentList = rtnData.data.agentVO
      })
    },
    //팝업 열기
    openMapPopup (terminalInfo) {
      this.parentInfoPort.catCd = 'S'
      this.parentInfoPort.portCd = this.currentTimeVO.plcCd
      this.parentInfoPort.lttd = terminalInfo.lttdNm || 0
      this.parentInfoPort.lngtd = terminalInfo.lngtdNm || 0

      const elemPopup = document.querySelector('#port_popup')
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.popup_cont > .popup_dim')
        elemPopup.style.display = 'none'
      }
      this.customComponent = 'port-location-pop'
      this.$ekmtcCommon.layerOpen('#port_popup')
    },
    //팝업 닫기
    closePopup (obj) {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.portTerminalCy .popup_dim')
    },
    //현지시간 및 시차 셋팅
    changeDateTime2Display (strDate, lang) {
      var rtnStrDate = ''
      if (!strDate) {
        rtnStrDate = ''
      } else {
        if (lang === 'ENG') {
          const engCalNm = [
                      ['01', '.Jan.'],
                      ['02', '.Feb.'],
                      ['03', '.Mar.'],
                      ['04', '.Apr.'],
                      ['05', '.May.'],
                      ['06', '.Jun.'],
                      ['07', '.Jul.'],
                      ['08', '.Aug.'],
                      ['09', '.Sep.'],
                      ['10', '.Oct.'],
                      ['11', '.Nov.'],
                      ['12', '.Dec.']
                  ]

          //month에 따른 영문 month명 셋팅
          engCalNm.map((item) => {
            if (strDate.substring(4, 6) === item[0]) {
              rtnStrDate = strDate.substring(0, 4) + item[1] + strDate.substring(6, 8)
            }
          })
        } else {
          if (strDate) {
            rtnStrDate = strDate.substring(0, 4) + '.' + strDate.substring(4, 6) + '.' + strDate.substring(6, 8)
          }
        }
        if (rtnStrDate) {
          rtnStrDate = rtnStrDate + ' ' + strDate.substring(8, 10) + ':' + strDate.substring(10, 12)
        }
      }
      return rtnStrDate
    }
  }
}
</script>
