<template>
  <div
    class="popup_wrap"
    style="width:800px; height:600px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <h1 class="page_title">Route</h1>
      <div class="content_box">
        <!-- content_box -->
        <h2 class="content_title">{{ courseInfo.rteCd }}</h2>
        <div v-if="serviceCtrCd !== 'CN'" class="map_wrap" style="height:400px;">
          <!--지도 영역을 표시하기 위해 임의로 height 값을 넣어놨습니다. 개발 작업 시 style="height:200px;" <- 이 부분 지워주세요 -->
          <!-- 지도 영역입니다. -->
          <GmapMap
            v-if="serviceCtrCd !== 'CN'"
            :center="center"
            :zoom="zoom"
            style="width: 100%; height: 100%"
          >
            <GmapMarker
              v-for="(m,index) in markersList"
              :key="index"
              :position="m.position"
              :icon="'/img/icon_harbor.png'"
              :label="m.portNm"
            />
            <GmapPolyline
              :path="pathListStr"
              :options="mapOptions"
            />
            <GmapPolyline
              :path="pathListEnd"
              :options="mapOptions2"
            />
          </GmapMap>
          <!--          <baidu-map-->
          <!--            v-if="serviceCtrCd == 'CN'"-->
          <!--            :center="center"-->
          <!--            :zoom="zoom"-->
          <!--            style="width: 100%; height: 100%"-->
          <!--            @ready="baiduMapReady"-->
          <!--          >-->
          <!--            <bm-marker-->
          <!--              v-for="(m,index) in markersList"-->
          <!--              :key="index"-->
          <!--              :position="m.position"-->
          <!--              :icon="{ url: '/img/icon_harbor.png' }"-->
          <!--            >-->
          <!--              <bm-label-->
          <!--                :content="m.portNm"-->
          <!--              />-->
          <!--            </bm-marker>-->
          <!--            <bm-polyline-->
          <!--              :path="pathListStr"-->
          <!--              :stroke-color="mapOptions.strokeColor"-->
          <!--              :stroke-opacity="mapOptions.strokeOpacity"-->
          <!--              :stroke-weight="mapOptions.strokeWeight"-->
          <!--              :icons="mapOptions.icons"-->
          <!--            />-->
          <!--            <bm-polyline-->
          <!--              :path="pathListEnd"-->
          <!--              :stroke-color="mapOptions2.strokeColor"-->
          <!--              :stroke-opacity="mapOptions2.strokeOpacity"-->
          <!--              :stroke-weight="mapOptions2.strokeWeight"-->
          <!--              :icons="mapOptions2.icons"-->
          <!--            />-->
          <!--          </baidu-map>-->
        </div>
        <table class="tbl_col">
          <colgroup>
            <col width="14%">
            <col width="20%">
            <col width="38%">
            <col width="14%">
            <col width="14%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.VOSD100_M3.004') }}</th><!-- 지역 -->
              <th>{{ $t('msg.VOSD100_M3.005') }}</th><!-- 항구 -->
              <th>{{ $t('msg.VOSD100_M3.006') }}</th><!-- Terminal -->
              <th>{{ $t('msg.VOSD100_M3.007') }}</th><!-- 수입 & 입항일 -->
              <th>{{ $t('msg.VOSD100_M3.008') }}</th><!-- 수출 & 출항일 -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(list,index) in courseInfoList" :key="index">
              <td>{{ list.ctrNm }}</td>
              <td>{{ list.portNm }}</td>
              <td>{{ list.trmlEnm }}</td>
              <td>{{ list.etaWdayCd }}, {{ list.etaTm.substring(0, 2) }}:{{ list.etaTm.substring(2, 4) }}</td>
              <td>{{ list.etdWdayCd }}, {{ list.etdTm.substring(0, 2) }}:{{ list.etdTm.substring(2, 4) }}</td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->
      <!-- 닫기 -->
      <div class="mt10 text_center">
        <a
          class="button gray lg"
          href="#"
          @click.prevent="$emit('close')"
        >
          {{ $t('msg.SCHD010P060.002') }}
        </a>
      </div>
    </div><!-- popup_cont -->
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import RouteInfo from '@/pages/schedule/RouteInfo'

export default {
  name: 'RoutePop',
  components: {
    RouteInfo
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          rteCd: '',
          vesselNm: '',
          vesselCd: '',
          vslVoy: '',
          catCd: 'P',
          portCd: 'TKD'
        }
      }
     }
  },
  data: function () {
    return {
      courseInfo: {},
      courseInfoList: [],
      rteCd: '',
      vesselNm: '',
      vesselCd: '',
      vslVoy: '',
      yyyymmdd: '',
      serviceCtrCd: '',
      // center: {
      //   lat: parseFloat(37.564214),
      //   lng: parseFloat(127.001699)
      // },
      center: { lat: 55.915655, lng: -4.744502 },
      zoom: 3,
      markersList: [],
      positionCover: {},
      position: {},
      path: [
        // { lat: 1.33, lng: 103.75 },
        // { lat: 1.43, lng: 103.85 }
        { lat: 37.772, lng: -122.214 },
        { lat: 21.291, lng: -157.821 },
        { lat: -18.142, lng: 178.431 },
        { lat: -27.467, lng: 153.027 }
      ],
      pathListStr: [],
      pathListEnd: [],
      pathList: [],
      mapOptions: {},
      mapOptions2: {}
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    if (!this.auth.userId) {
      this.serviceCtrCd = this.$ekmtcCommon.isNotEmpty(localStorage.getItem('service_ctrcd')) ? localStorage.getItem('service_ctrcd') : localStorage.getItem('service_lang')
    } else {
      this.serviceCtrCd = this.memberDetail.userCtrCd
    }

    const date = new Date()

    this.year = date.getFullYear()
    this.month = date.getMonth() + 1
    this.day = date.getDate()

    if (this.month < 10) {
      this.month = '0' + this.month
    }
    if (this.day < 10) {
      this.day = '0' + this.day
    }

    this.yyyymmdd = this.year + '' + this.month + '' + this.day

    setTimeout(() => {
      if (this.serviceCtrCd !== 'CN') {
        this.setGoogleMap()
        this.getServiceCourseInfo()
      } else {
        this.getServiceCourseInfo()
      }
    }, 100)
  },
  methods: {
    baiduMapReady ({ BMap, map }) {
      this.setBaiduMap(BMap, map)
      this.getServiceCourseInfo()
    },
    setGoogleMap () {
      this.mapOptions = {
        strokeColor: '#000080',
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        geodesic: true,
        icons: [{
          icon: {
            strokeColor: '#000080',
            scale: 2.5,
            fillOpacity: 0.5,
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
          },
          offset: '100%',
          repeat: '200px'
        }]
      }
      this.mapOptions2 = {
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        geodesic: true,
        icons: [{
          icon: {
            strokeColor: '#FF0000',
            scale: 2.5,
            fillOpacity: 0.5,
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
          },
          offset: '100%',
          repeat: '200px'
        }]
      }
    },
    setBaiduMap (BMap, map) {
      this.mapOptions = {
        strokeColor: '#000080',
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        icons: []
      }
      const startSy = new BMap.Symbol(
        BMap_Symbol_SHAPE_FORWARD_CLOSED_ARROW,
        {
          strokeColor: '#000080',
          scale: 2.5,
          fillOpacity: 0.5
        }
      )
      mapOptions.icons.push(new BMap.IconSequence(startSy, '100%', '200px'))

      this.mapOptions2 = {
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        icons: []
      }
      const endSy = new BMap.Symbol(
        BMap_Symbol_SHAPE_FORWARD_CLOSED_ARROW,
        {
          strokeColor: '#FF0000',
          scale: 2.5,
          fillOpacity: 0.5
        }
      )
      mapOptions2.icons.push(new BMap.IconSequence(endSy, '100%', '200px'))
    },
    getServiceCourseInfo () {
      const params = {
        vslNm: this.parentInfo.vesselNm,
        vslCd: this.vesselCd,
        rteCd: this.parentInfo.rteCd,
        searchDate: this.yyyymmdd,
        vslVoy: this.vslVoy
      }
      vslScheduleInfo.getServiceCourseInfoVer(params).then((rtnData) => {
        this.courseInfoList = rtnData.data.vesselScheduleList
        this.courseInfo = rtnData.data.serviceCourseInfo
        this.center.lat = parseFloat(this.courseInfo.courseLat)
        this.center.lng = parseFloat(this.courseInfo.courseLng)
        //this.zoom = parseInt(this.courseInfo.zoom)
        const strEnd = this.courseInfo.startEnd
        const strEndArr = strEnd.split(',')
        let i = 0
        this.markersList = []

        const pointInfo = rtnData.data.pointInfo.points
        const fromPointCount = rtnData.data.pointInfo.fromPointCount

        for (const item of this.courseInfoList) {
          this.position = {}
          this.position.lat = parseFloat(item.lttdNm)
          this.position.lng = parseFloat(item.lngtdNm)
          this.pathList.push(this.position)
          this.positionCover = {}
          this.positionCover.position = this.position
          this.positionCover.portNm = item.portNm
          this.markersList.push(this.positionCover)
          i++
        }

        const arrPoint = pointInfo.split(', ')

        for (let i = 0; i < arrPoint.length; i++) {
          const p = arrPoint[i]
          if (this.$ekmtcCommon.isNotEmpty(p)) {
            const lat = p.split(',')[0]
            const lng = p.split(',')[1]
            const obj = {
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            }
            if (i < Number(fromPointCount)) {
              this.pathListStr.push(obj)
            } else {
              this.pathListEnd.push(obj)
            }
          }
        }
      })
    }
  }
}
</script>
